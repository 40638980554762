
import MainTable from "@/modules/purchaseOrders/components/MainTable.vue";
import SearchForm from "@/modules/purchaseOrders/components/SearchForm.vue";
export default {
    components: {SearchForm, MainTable},
    setup() {
        const tableName = 'purchaseOrders'
        return {
            tableName
        }
    }
}
